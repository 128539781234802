import React from 'react';
import * as styles from './YourCreativeTeam.module.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Button, ButtonStyle } from '../generic/Button';

export const YourCreativeTeam = (): JSX.Element => {
  return (
    <div>
      <section data-testid="YourCreativeTeam" className={styles.YourCreativeTeam}>
        <StaticImage className={styles.YourCreativeTeam__image} placeholder="none" quality={100} src="../../assets/homepage/your-creative-team.png" alt=""/>
        <div className={styles.YourCreativeTeam__content}>
          <h2 className={styles.YourCreativeTeam__title}>
            <span>An Extension of</span> Your Creative Team
          </h2>
          <p className={styles.YourCreativeTeam__subtitle}>Our designers work seamlessly with internal creative teams and brand guidelines to bring optimization ideas and results to life.</p>
          <div className={styles.YourCreativeTeam__buttons}>
            <Button href="/our-work" style={ButtonStyle.FilledSquareCta}>See Our Work</Button>
            <Button href="https://crometrics.com/contact/" style={ButtonStyle.InverseFilledSquareCta}>Request Information</Button>
          </div>
        </div>
      </section>
    </div>
  );
};