import React from 'react';
import { SEO } from '../components/generic/SEO';
import { HomepagePage } from '../components/pages/Homepage';
import '../styles/global.scss';

const Homepage = (): JSX.Element => {
  return <HomepagePage/>;
};
export default Homepage;

export const Head = (): JSX.Element => {
  return <SEO pathname="/"/>;
};