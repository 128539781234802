import React from 'react';
import * as styles from './ServiceCard.module.scss';
import { Button, ButtonStyle } from '../generic/Button';
import ArrowSvg from '../../assets/arrow.svg';

export interface IServiceCardProps {
  headline: string;
  description: string;
  icon: JSX.Element;
  href?: string;
}

export const ServiceCard = ({ icon, headline, description, href }: IServiceCardProps): JSX.Element => {
  const getLearnMoreButton = (): JSX.Element | null => {
    if (!href) {
      return null;
    }

    return (
      <Button href={href} style={ButtonStyle.PopoutCta}>
        Learn More <ArrowSvg/>
      </Button>
    );
  };

  return (
    <div data-testid="ServiceCard" className={styles.ServiceCard}>
      {icon}
      <h2>{headline}</h2>
      <p>{description}</p>
      {getLearnMoreButton()}
    </div>
  );
};