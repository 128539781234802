import React from 'react';
import * as styles from './ServiceSection.module.scss';
import { ServiceCard, IServiceCardProps } from './ServiceCard';
import ExperimentationSvg from '../../assets/homepage/services/experimentation.svg';
import LifecycleSvg from '../../assets/homepage/services/lifecycle.svg';
import PerformanceMarketingSvg from '../../assets/homepage/services/performance-marketing.svg';
import LandingPagesSvg from '../../assets/homepage/services/landing-pages.svg';
import RedesignSvg from '../../assets/homepage/services/redesign.svg';
import { Button, ButtonStyle } from '../generic/Button';

export const ServiceSection = (): JSX.Element => {
  const serviceInfo: IServiceCardProps[] = [
    {
      headline: 'CRO Experimentation',
      description: 'We create A/B test variations that fit seamlessly with your site content and brand aesthetic.',
      icon: <ExperimentationSvg/>,
      href: '/our-work/#cro-experimentation',
    }, 
    {
      headline: 'Lifecycle Marketing',
      description: 'We\'ll craft engaging, results-driven email designs that will invigorate your marketing efforts.',
      icon: <LifecycleSvg/>,
      href: '/our-work/#lifecycle-marketing',
    },
    {
      headline: 'Performance Marketing',
      description: 'Elevate your social media presence with original, attention-grabbing visuals.',
      icon: <PerformanceMarketingSvg/>,
      href: '/our-work/#performance-marketing',
    },
    {
      headline: 'Experiment-Led Redesigns',
      description: 'A blend of growth strategy and design execution will give you a fresh look while ensuring real revenue growth.',
      icon: <RedesignSvg/>,
    },
    {
      headline: 'Landing Pages',
      description: 'Looking for a cohesive, impactful landing page that speaks to your customers\' needs? Let our design experts craft something on-brand and beautiful.',
      icon: <LandingPagesSvg/>,
    },
  ];

  const createServiceCards = (): JSX.Element[] => {
    return serviceInfo.map((props: IServiceCardProps, index: number): JSX.Element => {
      return <ServiceCard key={index} {...props}/>;
    });
  };

  return (
    <section data-testid="ServiceSection" className={styles.ServiceSection}>
      {createServiceCards()}
      <div className={styles.ServiceSection__getInTouch}>
        <h2>Get in Touch</h2>
        <p>Find out how Cro Metrics provides everything from individual channel support to fully-managed experiment-led marketing programs.</p>
        <Button href="https://crometrics.com/contact/" style={ButtonStyle.FilledSquareCta}>Contact Us</Button>
      </div>
    </section>
  );
};