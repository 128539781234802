import React from 'react';
import { BrandDisplay } from '../homepage/BrandDisplay';
import { Layout } from '../generic/Layout';
import { HomepageHero } from '../homepage/HomepageHero';
import { YourCreativeTeam } from '../homepage/YourCreativeTeam';
import { ServiceSection } from '../homepage/ServiceSection';
import { Hero } from '../generic/Hero';

export const HomepagePage = (): JSX.Element => {
  return (
    <main data-testid="Homepage">
      <Layout>
        <Hero>
          <HomepageHero/>
        </Hero>
        <YourCreativeTeam/>
        <BrandDisplay/>
        <ServiceSection/>
      </Layout>
    </main>
  );
};