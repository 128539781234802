import React from 'react';
import { Button } from '../generic/Button';
import CloroxSvg from '../../assets/brands/clorox.svg';
import DoordashSvg from '../../assets/brands/doordash.svg';
import HomeChefSvg from '../../assets/brands/home-chef.svg';
import StarzSvg from '../../assets/brands/starz.svg';
import UnicefUsaSvg from '../../assets/brands/unicef-usa.svg';
import ZillowSvg from '../../assets/brands/zillow.svg';
import * as styles from './BrandDisplay.module.scss';

interface IBrand {
  url: string;
  logo: JSX.Element;
}

const brands: IBrand[] = [
  {
    url: 'https://www.unicefusa.org/',
    logo: <UnicefUsaSvg/>,
  },
  {
    url: 'https://www.clorox.com/',
    logo: <CloroxSvg/>,
  },
  {
    url: 'https://www.doordash.com/',
    logo: <DoordashSvg/>,
  },
  {
    url: 'https://www.starz.com/',
    logo: <StarzSvg/>,
  },
  {
    url: 'https://www.zillow.com/',
    logo: <ZillowSvg/>,
  },
  {
    url: 'https://www.homechef.com/',
    logo: <HomeChefSvg/>,
  },
];

export const BrandDisplay = (): JSX.Element => {
  const getBrandLogos = (): JSX.Element[] => {
    return brands.map(({ url, logo }: IBrand, index: number): JSX.Element => {
      return (
        <li key={index}>
          <Button href={url}>{logo}</Button>
        </li>
      );
    });
  };

  return (
    <div className={styles.BrandDisplay__wrapper}>
      <section data-testid="BrandDisplay" className={styles.BrandDisplay}>
        <h2>
          Trusted by <span>Today's Top Brands</span>
        </h2>
        <ul className={styles.BrandDisplay__brands}>{getBrandLogos()}</ul>
      </section>
    </div>
  );
};