import React from 'react';
import { Button, ButtonStyle } from '../generic/Button';
import * as styles from './HomepageHero.module.scss';
import DottedLineSvg from '../../assets/dotted-line.svg';
import HeroBg from '../../assets/homepage/homepage-hero-bg.svg';

export const HomepageHero = (): JSX.Element => {
  return (
    <div>
      <section data-testid="HomepageHero" className={styles.HomepageHero}>
        <DottedLineSvg/>
        <div className={styles.HomepageHero__content}>
          <h1 className={styles.HomepageHero__title}>
            Experts in <span>Conversion-Focused Creative</span>
          </h1>
          <p className={styles.HomepageHero__subtitle}>With our combined conversion rate optimization and web UX expertise, the Cro Metrics design team executes effective, well-thought out designs that drive results.</p>
          <Button href="/our-work" style={ButtonStyle.FilledSquareCta}>See Our Work</Button>
        </div>
        <HeroBg/>
      </section>
    </div>
  );
};